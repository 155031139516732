/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    em: "em",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "grid-column-start--end",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#grid-column-start--end",
    "aria-label": "grid column start  end permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Grid Column Start / End"), "\n", React.createElement(_components.p, null, "Utilities for controlling how elements are sized and placed across grid columns."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "gridColumn={value}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "grid-column: {value};"))))), "\n", React.createElement(_components.h2, {
    id: "spanning-columns",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#spanning-columns",
    "aria-label": "spanning columns permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Spanning columns"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "gridColumn=\"span n / span n\""), " utilities to make an element span ", React.createElement(_components.em, null, "n"), " columns."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=violet"
  }, "<>\n  <template preview>\n    <x.div display=\"grid\" gridTemplateColumns={3} gap={4}>\n      {Array.from({ length: 7 }, (_, index) => index + 1).map((value) => (\n        <x.div\n          key={value}\n          h={12}\n          borderRadius=\"md\"\n          bg={value === 4 || value === 7 ? 'violet-500' : 'violet-400'}\n          color=\"white\"\n          display=\"flex\"\n          alignItems=\"center\"\n          justifyContent=\"center\"\n          fontSize=\"2xl\"\n          fontWeight=\"extrabold\"\n          gridColumn={value === 4 || value === 7 ? 'span 2 / span 2' : ''}\n        >\n          {value}\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div display=\"grid\" gridTemplateColumns={3} gap={4}>\n    <x.div>1</x.div>\n    <x.div>2</x.div>\n    <x.div>3</x.div>\n    <x.div gridColumn=\"span 2 / span 2\">4</x.div>\n    <x.div>5</x.div>\n    <x.div>6</x.div>\n    <x.div gridColumn=\"span 2 / span 2\">7</x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "starting-and-ending-lines",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starting-and-ending-lines",
    "aria-label": "starting and ending lines permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starting and ending lines"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "gridColumn=\"n\""), " (start) and ", React.createElement(_components.code, null, "gridColumn=\"auto / n\""), " (end) utilities to make an element start or end at the nth grid line. These can also be combined with the ", React.createElement(_components.code, null, "gridColumn=\"n / span x\""), " (start) and ", React.createElement(_components.code, null, "gridColumn=\"span x / n\""), " (end) utilities to span a specific number of columns."), "\n", React.createElement(_components.p, null, "Note that CSS grid lines start at 1, not 0, so a full-width element in a 6-column grid would start at line 1 and end at line 7."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div display=\"grid\" gridTemplateColumns={6} gap={4}>\n      <x.div\n        h={12}\n        borderRadius=\"md\"\n        bg=\"emerald-500\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n        gridColumn=\"2 / span 4\"\n      >\n        1\n      </x.div>\n      <x.div\n        h={12}\n        borderRadius=\"md\"\n        bg=\"emerald-500\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n        gridColumn=\"1 / 3\"\n      >\n        2\n      </x.div>\n      <x.div\n        h={12}\n        borderRadius=\"md\"\n        bg=\"emerald-500\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n        gridColumn=\"span 2 / 7\"\n      >\n        3\n      </x.div>\n      <x.div\n        h={12}\n        borderRadius=\"md\"\n        bg=\"emerald-500\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n        gridColumn=\"1 / 7\"\n      >\n        4\n      </x.div>\n    </x.div>\n  </template>\n  <x.div display=\"grid\" gridTemplateColumns={6} gap={4}>\n    <x.div gridColumn=\"2 / span 4\">1</x.div>\n    <x.div gridColumn=\"1 / 3\">2</x.div>\n    <x.div gridColumn=\"span 2 / 7\">3</x.div>\n    <x.div gridColumn=\"1 / 7\">4</x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the column placement of an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "gridColumn={{ md: \"span 2 / span 2\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "gridColumn=\"span 2 / span 2\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div display=\"grid\" gridColumn={{ md: 'span 2 / span 2' }}>\n  {/* ... */}\n</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
